import { initAddStoreToRoute } from "@cosh-eco/stores-module/dist/cosh-stores";

import { Element } from "@/scripts/extends";

export default class AddStoreToRoute extends Element {

	events() {
		this.el.addEventListener("click", (e) => this.onClick(e));
	}

	onClick() {
		const storeId = this.el.getAttribute("data-store-id");
		const storeTitle = this.el.getAttribute("data-store-title");
		const userId = this.el.getAttribute("data-user-id");


		if (storeId) {
			initAddStoreToRoute("#cosh-stores-actions", {
				storeId,
			});

			if (window.dataLayer) {
				let dataLayerArr = {
					"event": "addtoroute",
					"stored_added": storeTitle,
					"uid": userId,
					"page_url": window.location.href,
				};

				if (dataLayerArr) {
					window.dataLayer.push(dataLayerArr);
				}
			}
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
