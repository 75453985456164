import { Element } from "@/scripts/extends";

export default class DataLayer extends Element {
	mounted() {
		this.handleClick = this.handleClick.bind(this);
	}

	events() {
		this.el.addEventListener("click", this.handleClick);
	}

	handleClick() {
		if (window.dataLayer) {
			let dataLayerProps = JSON.parse(this.el.getAttribute("data-layer"));

			let dataLayerArr = {
				"event": "custom_click",
				"button_label": this.el.innerText ?? "",
				"page_url": window.location.href,
			};

			// Override defaults
			const mergedConfig = {
				...dataLayerArr,
				...dataLayerProps
			};

			if (mergedConfig) {
				window.dataLayer.push(mergedConfig);
			}
		}
	}

	destroy() {
		this.el.removeEventListener("click", this.handleClick);
	}
}
