/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import Search from "@/scripts/components/Search";
import Flyout from "@/scripts/components/Flyout";
import Menu from "@/scripts/components/Menu";
import Copy from "@/scripts/components/Copy";
import Gallery from "@/scripts/components/Gallery";
import Tabs from "@/scripts/components/Tabs";
import Collapse from "@/scripts/components/Collapse";
import Carousel from "@/scripts/components/Carousel";
import Slider from "@/scripts/components/Slider";
import LinkCheck from "@/scripts/components/LinkCheck";
import StoreAction from "@/scripts/components/StoreAction";
import AddStoreToRoute from "@/scripts/components/AddStoreToRoute";
import AdTracking from "@/scripts/components/AdTracking";
import Popup from "@/scripts/components/Popup";
import DataLayer from "@/scripts/components/DataLayer";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	AdTracking,
	LinkCheck,
	Search,
	Flyout,
	Menu,
	Copy,
	Gallery,
	Tabs,
	Collapse,
	Carousel,
	Slider,
	StoreAction,
	AddStoreToRoute,
	Popup,
	DataLayer,
};
